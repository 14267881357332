var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");

window.onload = function() {  
	setupSlideMenu();
	setupScrollPadding();
	setupMapClick();
	StyleOnScroll();
	initGetQuote();

	var accordion = document.querySelectorAll(".accordion");
	for (var i = 0, len = accordion.length; i < len; i++) {
		initAccordion(accordion[i]);
	}
};

export function setupMapClick(){
	const map_click_list = document.querySelectorAll("#map-click, .map-click");
	if (map_click_list) {
		map_click_list.forEach((map_click) => {
			map_click.addEventListener("click", () => {
				// shield the map on mobile for easy scrolling
				map_click.style.display = "none";
			});
		});
	}
}

export function setupScrollPadding() {
	const header = document.querySelector("header");
	const html = document.querySelector("html");
	html.style.scrollPaddingTop = `${header.offsetHeight}px`;
}

export function setupSlideMenu() {
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			const body = document.querySelector("body");
			body.classList.toggle("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
		}
	});
	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});

}

export function initAccordion(accordionElem) {
	//when panel is clicked, handlePanelClick is called.          
	function handlePanelClick(event) {
		showPanel(event.currentTarget);
	}

	//Hide currentPanel and show new panel.  
	function showPanel(panel) {
		//Hide current one. First time it will be null. 
		var expandedPanel = accordionElem.querySelector(".active");
		if (expandedPanel) {
			expandedPanel.classList.remove("active");
		}

		//Show new one
		panel.classList.add("active");
	}

	var allPanelElems = accordionElem.querySelectorAll(".panel");
	for (var i = 0, len = allPanelElems.length; i < len; i++) {
		allPanelElems[i].addEventListener("click", handlePanelClick);
	}

	//By Default Show first panel
	if (allPanelElems.length >0){
		showPanel(allPanelElems[0]);
	}
}


export function StyleOnScroll() {
	var header = document.querySelector(".header-bar-top");
	var body = document.querySelector("body");

	function fadeLogo() {
		if (window.scrollY >= 10) {
			header.classList.add("darken");
			body.classList.add("scrolled");
		} else {
			header.classList.remove("darken");
			body.classList.remove("scrolled");
		}
	}
	document.addEventListener("scroll", fadeLogo);
	fadeLogo();
}

export function initGetQuote() {
	var quote_wrapper = document.querySelector(".quote-wrapper");
	quote_wrapper.addEventListener("click", hideQuote);

	function showQuote() {
		quote_wrapper.classList.add("active");
	}

	function hideQuote(e) {
		e = window.event || e;
		if (this === e.target) {
			// Should only fire if the quote wrapper was clicked
			// Without this, it would fire when a child is clicked due to event bubbling
			quote_wrapper.classList.remove("active");
		}
	}

	var get_a_quote_elm = document.querySelectorAll(".get-a-quote");
	for (var i = 0, len = get_a_quote_elm.length; i < len; i++) {
		get_a_quote_elm[i].addEventListener("click", showQuote);
	}

}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e) {
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});